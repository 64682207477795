/* styles.css */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 10px;
}

.loading-logo {
    width: 10%;
    animation: spin 4s linear infinite;
    opacity: 30%;
}

.loading-quote {
    font-size: 2.3rem;
    text-align: center;
    color: #FFA500; /* Orange */
    margin-bottom: 20px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.loading-screen h1 {
    font-size: 3rem;
    padding: 5%;
    color: chartreuse;
    font-family: 'Megrim', cursive;
}

.loading-screen h2 {
    font-size: 3rem;
    color: aquamarine;
    font-family: 'Megrim', cursive;
}

.loading-screen p {
    font-size: 1.2rem;
    padding: 10%;
    color: orange;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

/* Mobile view */
@media (max-width: 768px) {
    .loading-logo {
        width: 25%;
    }

    .loading-quote {
        font-size: 1rem;
    }
}
