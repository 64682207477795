.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-family: 'Megrim', cursive;
  background-color: #000;
  color: orange;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 2px solid #83c744;
  z-index: 1000;
}


.nav-logo {
  font-size: 28px;
}

.nav-logo img {
  width: 33px;
  height: 33px;
  padding-right: 13px;
}

.navbar a[href] {
  color: aquamarine;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

a[href]:hover {
  border-bottom: 2px solid orange;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding-right: 10%;
}

.nav-links li a {
  color: #83c744;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: orange;
}

/* Sci-fi technology theme */
.navbar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, transparent, #66ff7a, transparent);
  animation: scan-animation 9s linear infinite;
}

@keyframes scan-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}


/* Mobile view */
@media (max-width: 900px) {
  .navbar {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .nav-logo a{
    font-size: x-large;
    margin-bottom: 10px;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
  }

  .nav-links li {
    margin: 5px 0;
  }
}


